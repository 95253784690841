import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import "../styles/main.scss";
import HelmetScripts from "../components/HelmetScripts";
import SocialMedia from "../components/common/SocialMedia";
import CookieBar from "../components/common/CookieBar";
import { getCookie, setCookie } from "../utils/cookie";
import { getHrefs } from "../utils/langService";

import imgGetinweb from "../images/common/getinweb.svg";

const ErrorLayout = ({ children, t, lang }) => {
  const [cookieBar, setCookieBar] = useState(false);

  useEffect(() => {
    if (!getCookie("ca")) setCookieBar(true);
  }, []);

  const closeCookieBar = () => {
    setCookie("ca", true, 365);
    setCookieBar(false);
  };

  const { pages } = getHrefs(lang);

  return (
    <>
      <HelmetScripts />

      <header className="error-header">
        <div className="container">
          <Link className="link-flex" to={`/${lang}/`}>
            <img className="Header__logo" src={imgGetinweb} alt="Getinweb" />
          </Link>
        </div>
      </header>

      <main>
        <div className="wrapper error-wrapper">{children}</div>

        {cookieBar && <CookieBar t={t.cookieBar} lang={lang} close={closeCookieBar} />}
      </main>

      <footer className="Footer">
        <div className="container">
          <div className="Footer__bottom">
            <div className="Footer__copy">
              <p>&copy; {new Date().getFullYear()} Getinweb. All rights reserved.</p>
              <Link to={`/${lang}/${pages[5]}/`}>{t.footer.other.privacyPolicy}</Link>
              <Link to={`/${lang}/${pages[6]}/`}>{t.footer.other.terms}</Link>
            </div>

            <SocialMedia />
          </div>
        </div>
      </footer>
    </>
  );
};

ErrorLayout.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired
};

export default ErrorLayout;
