import React from "react";
import Seo from "../components/seo";
import ErrorLayout from "../layouts/ErrorLayout";
import LinkButton from "../components/common/LinkButton";

import { langsList } from "../utils/langService";

import layoutTPL from "../static/locales/pl/layout.json";
import layoutTEN from "../static/locales/en/layout.json";
import tPL from "../static/locales/pl/404.json";
import tEN from "../static/locales/en/404.json";

import imgNotFound from "../images/not-found.svg";

const NotFoundPage = ({ location }) => {
  const lang = langsList.find(elem => elem === location.pathname.split("/")[1]) || langsList[0];
  const layoutT = lang === "en" ? layoutTEN : layoutTPL;
  const t = lang === "en" ? tEN : tPL;

  return (
    <ErrorLayout t={layoutT} lang={lang}>
      <Seo title={t.seo.title} description={t.seo.description} />

      <img className="error-image" src={imgNotFound} alt={t.title} />

      <h2 className="title">{t.title}</h2>

      <LinkButton to={`/${lang}/`}>{t.goToHomePage}</LinkButton>
    </ErrorLayout>
  );
};

export default NotFoundPage;
